import React from 'react';
import Layout from '../../components/Shared/Layout';
import Genopets from '../../components/Portfolio/genopets';
import '../../assets/css/jobbox.css';
import * as ReactGA from 'react-ga';

const $ = typeof window !== `undefined` ? require('jquery') : null;

class GenopetsPage extends React.Component {
  /* constructor(props) {
      super(props);
  } */
  componentDidMount() {
    ReactGA.set({
      title: 'Portfolio/jobbox',
    });
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <Layout>
        <Genopets />
      </Layout>
    );
  }
}

export default GenopetsPage;
